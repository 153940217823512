export default class Resizeimg {
    /**
     * Méthode constructeur
     * @param {HTMLElement} element - Élément HTML sur lequel la composante est instanciée
     */
    constructor(element) {
      this.element = element;
      this.init();
    }
  
    /**
     * Initialisation de la composante
     */
    init() {
      const realImgWidth = $("#realimg").width();
      $(this.element).find(".resize img").css("width", realImgWidth);
  
      // Attach event listeners
      this.drags($(this.element).find(".handle"), $(this.element).find(".resize"), $(this.element));
      
      // On window resize, adjust image width
      $(window).resize(() => {
        this.onResize();
      });
    }
  
    /**
     * Méthode pour ajuster la largeur de l'image lors du redimensionnement de la fenêtre
     */
    onResize() {
      const realImgWidth = $("#realimg").width();
      $(this.element).find(".resize img").css("width", realImgWidth);
    }
  
    /**
     * Gère les événements de glissement pour redimensionner l'image
     * @param {jQuery} e - Élément de poignée à glisser
     * @param {jQuery} a - Élément à redimensionner
     * @param {jQuery} t - Élément parent
     */
    drags(e, a, t) {
      e.on("mousedown touchstart", (i) => {
        e.addClass("draggable");
        a.addClass("resizable");
  
        let o = i.pageX ? i.pageX : i.originalEvent.touches[0].pageX,
            n = e.outerWidth(),
            s = e.offset().left + n - o,
            l = t.offset().left,
            r = t.outerWidth();
  
        let minLeft = l + 10;
        let maxLeft = l + r - n - 10;
  
        e.parents().on("mousemove touchmove", (event) => {
          let t = event.pageX ? event.pageX : event.originalEvent.touches[0].pageX;
          let leftValue = t + s - n;
  
          leftValue = Math.max(minLeft, Math.min(leftValue, maxLeft));
          let widthValue = 100 * (leftValue + n / 2 - l) / r + "%";
  
          $(".draggable").css("left", widthValue).on("mouseup touchend touchcancel", () => {
            $(".draggable").removeClass("draggable");
            a.removeClass("resizable");
          });
  
          $(".resizable").css("width", widthValue);
        }).on("mouseup touchend touchcancel", () => {
          e.removeClass("draggable");
          a.removeClass("resizable");
        });
  
        i.preventDefault();
      }).on("mouseup touchend touchcancel", () => {
        e.removeClass("draggable");
        a.removeClass("resizable");
      });
    }
  }
  
  // Initialisation du slider au chargement du document
  $(document).ready(() => {
    $(".ba-slider").each(function () {
      const element = $(this);
      const resizeInstance = new Resizeimg(element);
    });
  });
  