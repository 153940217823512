export default class SearchBlogPosts {
  constructor(element) {
    this.element = element;

    this.searchInput = document.getElementById("search-blog");
    this.suggestionsBox = document.getElementById("suggestions");
    this.lang = document.querySelector("html").getAttribute("lang");
    this.url = window.location.href + "/";

    this.init();
  }

  init() {
    this.searchInput.addEventListener("input", this.search.bind(this));
    this.searchInput.addEventListener("focus", this.search.bind(this));
    this.suggestionsBox.addEventListener("click", this.selectSuggestion.bind(this));
    document.addEventListener("click", this.closeSuggestion.bind(this));
  }

  search(event) {
    const query = event.target.value;

    if (query.length > 2) {
      const xhr = new XMLHttpRequest();
      xhr.open("POST", "/controllers/search_blog_posts.php", true);
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
      xhr.onload = () => {
        if (xhr.status === 200) {
          this.suggestionsBox.style.display = "block";
          this.suggestionsBox.innerHTML = xhr.responseText;
        }
      };
      xhr.send("query=" + encodeURIComponent(query) + "&lang=" + encodeURIComponent(this.lang) + "&url=" + encodeURIComponent(this.url));
    } else {
      this.suggestionsBox.style.display = "none";
    }
  }

  selectSuggestion(e) {
    if (e.target && e.target.matches(".suggestion-item")) {
      window.location.href = e.target.getAttribute("data-url");
    }
  }

  closeSuggestion(e) {
    if (!e.target.closest("#search-blog, #suggestions")) {
      this.suggestionsBox.style.display = "none";
    }
  }
}
